<template>
    <div class="allocatepage-menu">
        <div class="item-box" v-for="(item, idx) in menuDatas" :key="idx" @click="click(item, idx)">
            <img class="icon" :src="item.icon" :style="{ width: 40 * wp + 'px', height: 40 * hp + 'px' }" />
            <div class="text" :style="{ fontSize: 16 * wp + 'px' }">{{ item.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menuType: { type: String, default: "page" }, //菜单模式:page是分页切换，pulldown为下拉
        rows: { type: Number, default: 3 }, //行数，menuType=page时有效
        columnNum: { type: Number, default: 4 }, //列数
        mode: { type: String, default: "square" }, //布局模式:square,rect
        hasBorder: { type: Boolean, default: false }, //是否有边框
        shadow: { type: Boolean, default: false }, //是否有阴影
        indicatorColor: { type: String, default: "#c5c5c5" }, //指示点颜色，menuType=page时有效
        indicatorActiveColor: { type: String, default: "#BE1C2C" }, //当前指示点颜色，menuType=page时有效
        dataFrom: { type: String, default: "" }, //数据来源
        menuCode: { type: String, default: "" }, //当前菜单代码:sysModuleId
        menuList: null,
        // {
        //   //menu数组
        //   type: Array,
        //   default: () => {
        //     return [
        //       // {
        //       //  image: "",//图标
        //       //  value: "",//标题
        //       //  url:"",//链接
        //       // }
        //     ];
        //   },
        // },
        wp: { type: Number, default: 1 },
        hp: { type: Number, default: 1 },
    },
    watch: {
        menuList: {
            handler(val, oldVal) {
                if (this.dataFrom != "menu") {
                    this.menuDatas = this.menuList || []
                }
            },
            deep: true,
        },
    },
    data() {
        return {
            swiperMenu: "Menu" + this.$core.createID(),
            menulistAnimate: "menulistAnimate",
            menulistScrool: { scroolTop: 0, scroll: false },
            menuDatas: [],
        }
    },
    created() {
        if (this.dataFrom == "menu") {
            this.getSystemMenu()
        } else {
            this.menuDatas = this.menuList || []
        }
    },

    methods: {
        getSystemMenu() {
            this.$get("/auth/api/auth/pc/systemModule/cascade", { parentId: this.menuCode }).then(res => {
                // console.log("=", res);
                if (res.code == "200" && res.dataList) {
                    res.dataList.map(item => {
                        if (item.type == "1") {
                            //是菜单
                            item.image = item.icon || "./images/none.png"
                            item.value = item.name
                            // IndexedDB.addOrUpdate("tbMenu", item).then((e) => {
                            //   // console.log("back", e.code);
                            // });
                            this.menuDatas.push(item)
                        }
                    })
                }
            })
        },
        click(item, index) {
            var params = {}

            if (this.dataFrom == "menu") {
                params.menuCode = item.sysModuleId
                params.moduleCode = item.code
            } else {
                if (item.menuCode) params.menuCode = item.menuCode
                if (item.code) params.code = item.code
            }

            params.url = item.url
            try {
                if (item.jumpJson) {
                    params.jumpJson = JSON.parse(item.jumpJson)
                }
            } catch (e) {}
            // console.log(params);
            this.$emit("click", params)
        },
        //列表更多栏目
        clickMoveMenu() {
            if (this.menulistAnimate == "closeMenulistAnimate" || this.menulistAnimate == "menulistAnimate") {
                this.menulistAnimate = "openMenulistAnimate"
                this.menulistScrool = { scrollTop: 0.1, scroll: true }
            } else {
                this.menulistAnimate = "closeMenulistAnimate"
                this.menulistScrool = { scrollTop: 0.2, scroll: false }
            }
        },
    },
}
</script>

<style lang="less">
.allocatepage-menu {
    display: flex;
    flex-wrap: wrap;

    > .item-box {
        width: 33.33%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon {
            width: 40px;
            height: 40px;
        }

        .text {
            font-size: 14px;
            color: #333;
            margin-top: 10px;
        }
    }
}
</style>
